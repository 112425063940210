import { useEffect, useState } from 'react';
import setCookie from '../../hooks/setCookie';
import getCookie from '../../hooks/getCookie';
import styles from './styles.module.scss';
import { useRouter } from 'next/router';

const dots = <svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="16" height="16" fill="#C9C9C9"/>
<path d="M9.5 13C9.5 13.8284 8.82843 14.5 8 14.5C7.17157 14.5 6.5 13.8284 6.5 13C6.5 12.1716 7.17157 11.5 8 11.5C8.82843 11.5 9.5 12.1716 9.5 13ZM9.5 8C9.5 8.82843 8.82843 9.5 8 9.5C7.17157 9.5 6.5 8.82843 6.5 8C6.5 7.17157 7.17157 6.5 8 6.5C8.82843 6.5 9.5 7.17157 9.5 8ZM9.5 3C9.5 3.82843 8.82843 4.5 8 4.5C7.17157 4.5 6.5 3.82843 6.5 3C6.5 2.17157 7.17157 1.5 8 1.5C8.82843 1.5 9.5 2.17157 9.5 3Z" fill="black"/>
</svg>


const share = <svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="16" height="16" fill="#C9C9C9"/>
  <path d="M2.375 9.04317C2.58211 9.04317 2.75 9.22033 2.75 9.43886V11.4173C2.75 11.8543 3.08579 12.2086 3.5 12.2086H12.5C12.9142 12.2086 13.25 11.8543 13.25 11.4173V9.43886C13.25 9.22033 13.4179 9.04317 13.625 9.04317C13.8321 9.04317 14 9.22033 14 9.43886V11.4173C14 12.2914 13.3284 13 12.5 13H3.5C2.67157 13 2 12.2914 2 11.4173V9.43886C2 9.22033 2.16789 9.04317 2.375 9.04317Z" fill="black"/>
  <path d="M7.73484 2.11589C7.88128 1.96137 8.11872 1.96137 8.26517 2.11589L10.5152 4.48999C10.6616 4.64451 10.6616 4.89505 10.5152 5.04957C10.3687 5.20409 10.1313 5.20409 9.98483 5.04957L8.375 3.35095V10.3093C8.375 10.5279 8.20711 10.705 8 10.705C7.79289 10.705 7.625 10.5279 7.625 10.3093V3.35095L6.01516 5.04957C5.86872 5.20409 5.63128 5.20409 5.48484 5.04957C5.33839 4.89505 5.33839 4.64451 5.48484 4.48999L7.73484 2.11589Z" fill="black"/>
</svg>

const PwaInstallInstruction = () => {
  const router = useRouter();

  const [showInstruction, setShowInstruction] = useState(false);
  const [activeInstruction, setActiveInstruction] = useState(0);
  const [pwaPromptCookie, setPwaPromptCookie] = useState('dontShow')
  const [isKeyboardVisible, setKeyboardVisible] = useState(false);

  const isMobileDevice = () => {
    return /Mobi|Android/i.test(navigator.userAgent) || window.innerWidth <= 768;
  };

  useEffect(() => {
    const handleFocus = (e) => {
      if ((e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA') && isMobileDevice()) {
        setKeyboardVisible(true)
      }
    };

    const handleBlur = (e) => {
      if ((e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA') && isMobileDevice) {
        setKeyboardVisible(false)
      }
    };

    document.addEventListener('focusin', handleFocus);
    document.addEventListener('focusout', handleBlur);

    return () => {
      document.removeEventListener('focusin', handleFocus);
      document.removeEventListener('focusout', handleBlur);
    };
  }, []);

  useEffect(() => {
    const pwaPromptCookie = getCookie('pwaPrompt');
    if (pwaPromptCookie) {
      setPwaPromptCookie(pwaPromptCookie)
    } else {
      setPwaPromptCookie(undefined)
    }
  }, []);

  const handleAddButtonClick = () => {
    setShowInstruction(!showInstruction);
    ym(process.env.NEXT_PUBLIC_YANDEX_KEY,'reachGoal','open_instruction');
  };

  const handleCloseButtonClick = () => {
    setShowInstruction(false);
  };

  const noShow = () => {
    setCookie('pwaPrompt', 'dontShow')
    setPwaPromptCookie('dontShow')
  }

  if (pwaPromptCookie == 'dontShow' || !router.pathname.startsWith('/profile')) {
    return null;
  }

  return (
    <div className={styles.pwaPrompt} style={{display: isKeyboardVisible ? 'none' : '',}}>
      <p className={styles.title}>Добавить иконку веб-приложения на главный экран</p>
      <button className={styles.addButton} onClick={handleAddButtonClick}>
        Добавить
      </button>
      <p className={styles.noShow} onClick={noShow}>больше не показывать</p>

      <div
        className={`${styles.instruct} ${showInstruction ? styles.show : ''}`}
        style={{transform: isKeyboardVisible ? 'translateY(100%)' : '',}}
      >
        <button className={styles.closeButton} onClick={handleCloseButtonClick}>
          &times;
        </button>

        <div className={styles.content}>
          <h3>Как добавить иконку веб-приложения<br /> на главный экран</h3>
          <div className={styles.types}>
            <p
              className={`${activeInstruction === 0 ? styles.active : ''}`}
              onClick={() => setActiveInstruction(0)}
            >
              Андроид
            </p>
            <p
              className={`${activeInstruction === 1 ? styles.active : ''}`}
              onClick={() => setActiveInstruction(1)}
            >
              Айфон
            </p>
          </div>

          <div className={styles.instructions}>
            {
              activeInstruction === 0
              ?
              <ol>
                <li>Зайдите в DonorSearch с телефона через Google Chrome.</li>
                <li>Нажмите на {dots} <b>три точки</b> в правом верхнем углу.</li>
                <li>Выберите <b>Добавить на главный экран</b>. Иконка готова!</li>
              </ol>
              :
              <ol>
                <li>Зайдите в DonorSearch с телефона и нажмите {share} <b>Поделиться</b> в Safari</li>
                <li>В выпадающем меню найдите <b>На экран «Домой»</b>, затем <b>Добавить</b>. Иконка готова!</li>
              </ol>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default PwaInstallInstruction;
